import { MenuDM } from "../../../shared/types";

export const altCategories = [
    { label: 'Press Releases', uniqueName: 'press.releases' },
    { label: 'Press Releases copy 1', uniqueName: 'press.releases1' },
    { label: 'Press Releases copy 2', uniqueName: 'press.releases2' },
    { label: 'Press Releases copy 3', uniqueName: 'press.releases3' },
  ];


  export const getNewsImage = (data: MenuDM[]) => {
    const newsItems = data.filter(a => a.contentUniquename?.toLowerCase() === 'news' || a.children.some(b => b.contentUniquename?.toLowerCase() === 'news'));
    if(newsItems){
      return newsItems[0].contentUniquename?.toLowerCase() === 'news' ? newsItems[0].mainImageFullUrl :
      newsItems[0].children.find(c => c.contentUniquename?.toLowerCase() === 'news')?.mainImageFullUrl;
    }
    return null
  }
