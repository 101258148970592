import { GetValue, SetCookie } from "../utils";

const useHellopPopCoockies = () => {
  const setHellopPopCookie = (): void => {
    SetCookie(24,"helloPopUp","true");
  };
  const setTemporaryHellopPopCookie = (): void => {
    if(GetValue("helloPopUp") === null){
      SetCookie(1,"helloPopUp","true");
    }
    
  };

  const getHellopPopCookieValue = (): string | null => {
    return GetValue("helloPopUp");
  };
  return { getHellopPopCookieValue, setHellopPopCookie,setTemporaryHellopPopCookie };
};

export default useHellopPopCoockies;
