import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ThemeDrawer from "./components/ThemeDrawer";
import GuardAppBar from "../../shared/components/header/GuardAppBar";
import FooterSection from "../homeIndex/components/FooterSection";
import SupportedBySection from "../homeIndex/components/SupportedBySection";
import WelcomPopup from "../homeIndex/components/WelcomPopup";
import NationalGuardTour from "./components/NationalGuardTour";
import useThemeChanger from "./hooks/useThemeChanger";
import { useLanguageID } from "../../shared/hooks/useLanguageID";
import HomeTopBannersIcons from "../homeIndex/components/pureComponents/HomeTopBannersIcons";

export default function Home() {
  const nagivate = useNavigate();
  const [top, setTop] = useState("50%");
  const { setArabic } = useThemeChanger();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const languageId = useLanguageID();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/Home") {
      setTop("50%");
    } else {
      setTop("35%");
    }
  }, [location]);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      nagivate("Loading")
    }
    setArabic();
  }, []);
  document.title =
    theme.direction === "rtl" ? "الحرس الوطني" : "National Guard";
  return (
    <div>
      <NationalGuardTour />
      <Box sx={{ opacity: openDrawer ? 0.2 : 1 }}>
        <GuardAppBar handleDrawerOpen={handleDrawerOpen} />
        <WelcomPopup />
        <Box position={"relative"}>
          <Outlet />
          <Box
            position={"fixed"}
            zIndex={4000}
            top={top}
            right={languageId === 1 ? "1%" : "95%"}
          >
            <HomeTopBannersIcons />
          </Box>
        </Box>
        <Box marginX={"8%"} mt={6} mb={2}>
          <SupportedBySection />
        </Box>
        <FooterSection />
      </Box>
      <ThemeDrawer
        drawerState={openDrawer}
        handleDrawerClose={handleDrawerClose}
      />
    </div>
  );
}
