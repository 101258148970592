import { Box, Typography } from "@mui/material";
import { useEffect } from "react";

export default function NotAuthorized() {
  useEffect(() => {
    localStorage.setItem("NotAuthorized", "");
  },[])
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      p={10}
    >
      <Typography fontFamily={'Roboto'} fontWeight={"600"} color={"primary"} variant="h2">
        Sorry, Server is not available now
      </Typography>
    </Box>
  );
}
