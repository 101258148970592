import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Android, Apple } from "@mui/icons-material";

import {
  PluginDM,
  SocialMediaDM,
  RelatedLink,
  BannersByCategoryDM,
} from "../../types";
import HuaweiIcon from "../../../../shared/components/svg/HuawiIcon";
interface FooterSecondaryInformationProp {
  pluginsRQRData: PluginDM[] | undefined;
  socialMediaRQRData: SocialMediaDM[] | undefined;
  relatedLinks: RelatedLink[] | undefined;
  mobileIconsRQRData: BannersByCategoryDM[] | undefined;
}
const FooterSecondaryInformation: React.FC<FooterSecondaryInformationProp> = (
  props
) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const borderRightSize = theme.direction === "ltr" ? "1px solid #ddd" : "0px";
  const borderLeftSize = theme.direction === "rtl" ? "1px solid #ddd" : "0px";
  return (
    <Grid
      container
      paddingX={"3%"}
      paddingY={2}
      justifyContent={"space-between"}
    >
      <Grid
        item
        xs={12}
        lg={2.5}
        borderLeft={borderLeftSize}
        borderRight={`${borderRightSize}`}
        paddingX={"2%"}
      >
        <Stack gap={2}>
          <Typography variant={"subtitle1"}>{t("plugins")}</Typography>
          <Box display={"flex"} gap={1}>
            {props.pluginsRQRData?.map((item, i) => {
              return (
                <IconButton
                  onClick={() => window.open(item.linkUrl, "_blank")}
                  title={item.title}
                  key={i}
                >
                  <img src={item.fullUrl} alt="image" />
                </IconButton>
              );
            })}
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        lg={2.0}
        borderLeft={borderLeftSize}
        borderRight={`${borderRightSize}`}
        paddingX={"2%"}
      >
        <Stack gap={2}>
          <Typography variant={"subtitle1"}>{t("download")}</Typography>
          <Box display={"flex"} gap={1}>
            {props.mobileIconsRQRData?.map((item, i) => {
              return (
                <Link key={i} href={item.linkUrl} target="_blank">
                  <IconButton
                    // onClick={() => window.open(item.linkUrl, "_blank")}
                    title={item.alternateText}
                  >
                    <img src={item.fullHoverImageUrl} alt="image" />
                  </IconButton>
                </Link>
              );
            })}
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        lg={5}
        borderLeft={borderLeftSize}
        borderRight={`${borderRightSize}`}
        paddingX={{ xs: "0%", sm: "2%" }}
      >
        {/*TODO:  Here we'll add relatedlinks */}
        {/* <Box display={"flex"}> */}
          <Grid container>
            {props.relatedLinks?.slice(0, 3).map((item, i) => {
              return (
                <Grid item xs={12} md={4} key={i}>
                  <IconButton
                    onClick={() => window.open(item.fullUrl, "_blank")}
                    title={item.title}
                  >
                    <img
                      src={item.thumbnail}
                      alt="image"
                      style={{
                        width: 140,
                        maxHeight:82
                      }}
                      // width={140}
                      // height={65}
                    />
                  </IconButton>
                </Grid>
              );
            })}
          </Grid>
        {/* </Box> */}
      </Grid>
      <Grid item xs={12} lg={2.5} paddingX={"2%"} id='social-media'>
        <Stack gap={2}>
          <Typography variant={"subtitle1"}>{t("followus")}</Typography>
          <Box display={"flex"} gap={1}>
            {props.socialMediaRQRData?.map((item, i) => {
              return (
                <IconButton
                  onClick={() => window.open(item.linkUrl, "_blank")}
                  title={item.title}
                  key={i}
                >
                  <img src={item.fullUrl} alt="image" style={{width:35,height:35}} />
                </IconButton>
              );
            })}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default FooterSecondaryInformation;
