import React from "react";
import { Box, Button, CardMedia, Stack, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useTranslation } from "react-i18next";

import { COLORS } from "../../../shared/constants";
import useHomeTopBanners from "../hooks/useHomeTopBanners";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import HomeTopBannerSkeleton from "../../../shared/components/skelton/HomeTopBannerSkeleton";
import HomeTopBannersIcons from "./pureComponents/HomeTopBannersIcons";

const UAEmap = require("../../../assets/images/UAEmap.png");

interface HomeTopBannersProp {}
//TODO: Refactoring
const HomeTopBanners: React.FC<HomeTopBannersProp> = (props) => {
  const { t } = useTranslation();
  const languageId = useLanguageID();
  const { data, isLoading, isError, error } = useHomeTopBanners(languageId);

  if (isLoading) {
    return <HomeTopBannerSkeleton />;
  }

  if (isError) {
    return null;
  }

  return (
    <Box maxHeight={'auto'} className="ignore-print">
      <Carousel
        indicators={true}
        activeIndicatorIconButtonProps={{
          style: {
            color: COLORS.primary,
          },
        }}
        indicatorContainerProps={{
          style: {
            position: "absolute",
            zIndex: 1,
            top: "80%",
            left: 0,
            right: 0,
            // marginTop: "34%",
          },
        }}
      >
        {data.map((item, i) => (
          <Box key={i}>
            <CardMedia
              component="img"
              sx={{
                borderBottom: "none",
                position: "relative",
                backgroundColor: COLORS.black,
                maxHeight: 'auto',
              }}
              image={item.fullUrl}
              alt="image"
            />
            <Box
              position={"absolute"}
              top={{
                xs: "10%",
                sm: "14%",
                md: "14%",
                lg: "16%",
                xl: "18%",
              }}
              width={"100%"}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                paddingX={8}
              >
                <Stack spacing={4} width={"50%"} mt={7}>
                  <Typography
                    fontSize={"2.7vw"}
                    variant={"h3"}
                    color={COLORS.white}
                  >
                    {item.title}
                    {/* {t("sliderH")} */}
                  </Typography>
                  <Typography
                    fontSize={"1.35vw"}
                    variant={"h5"}
                    color={COLORS.white}
                  >
                    {item.alternateText}
                    {/* {t("sliderP")} */}
                  </Typography>
                  {item.linkUrl && item.linkUrl.length > 0 && (
                    <Button
                      onClick={() =>
                        window.open(
                          item.linkUrl,
                          item.linkType === 2 ? "" : "_blank"
                        )
                      }
                      sx={{
                        color: COLORS.white,
                        width: "10vw",
                        height: "5vh",
                        alignSelf: "end",
                        display: {
                          xs: "none",
                          lg: "block",
                        },
                      }}
                      variant="contained"
                    >
                      {t("readmore")}
                    </Button>
                  )}
                </Stack>
                {/* <Box height={'100%'}>
                  <HomeTopBannersIcons />
                </Box> */}
              </Box>
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default HomeTopBanners;
