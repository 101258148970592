import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  CardActions,
  Grid,
  Stack,
  ButtonBase,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import useNewsLatest from "../hooks/useNewsLatest";
import { COLORS } from "../../../shared/constants";
import SectionHeader from "../../../shared/components/sectionHeader/SectionHeader";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import CustomSkeleton from "../../../shared/components/skelton/CustomSkeleton";
import { useNavigate } from "react-router-dom";
import { DateFormatedByLanguage } from "../../../shared/utils";

const newsAndUpdates1 = require("../../../assets/images/desktop.png");

interface NewsLatestSectionProp {
  withSectionHeader?: boolean;
  insie?: boolean;
}
// Refactor
const NewsLatestSection: React.FC<NewsLatestSectionProp> = (props) => {
  const { t } = useTranslation();
  const languageId = useLanguageID();
  const { data, isLoading, isError, error } = useNewsLatest(languageId);
  const navigation = useNavigate();

  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return null;
  }
  const navigateToDetails = (uniqueName: string, insie?: boolean) => {
    if (insie) {
      navigation(uniqueName);
    } else {
      navigation(`News/${uniqueName}`);
    }
  };
  const lang = languageId === 1 ? "en-US" : "ar";
  const lastItemFormattedDate = DateFormatedByLanguage(
    data[4]?.newsDate,
    languageId
  );
  return (
    <Box id="news-events">
      {!props.withSectionHeader && (
        <SectionHeader
          firstLine={t("featurednew")}
          firstLineColor={COLORS.black}
          secondLine={t("newsnupdates")}
          thirdtLine={t("readallnews")}
          onClick={() => navigation("news")}
        />
      )}
      {props.withSectionHeader && (
        <Typography
          variant="h4"
          sx={{
            display: "block",
            marginTop: 2,
            marginBottom: 3,
          }}
        >
          {t("newsnupdates")}
        </Typography>
      )}
      <Grid
        container
        sx={{ marginTop: 5, backgroundColor: COLORS.opacityGray }}
        justifyContent={{ xs: "center", sm: "flex-start" }}
      >
        <Grid xs={12} md={12} item container>
          {data?.slice(0, 5)?.map((item) => {
            const formattedDate = DateFormatedByLanguage(
              item.newsDate,
              languageId
            );
            return (
              <Grid
                key={item.uniqueName}
                item
                xs={12}
                md={6}
                lg={2.4}
                xl={2.4}
                display={"flex"}
                justifyContent={"center"}
                p={1}
              >
                <Card
                  variant="outlined"
                  sx={{
                    border: "none",
                    maxWidth: 240,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    transition: "0.5s",
                    "&:hover": {
                      transform: "Scale(1.1)",
                      zIndex: 2,
                    },
                  }}
                  onClick={() =>
                    navigateToDetails(item.uniqueName, props.insie)
                  }
                >
                  <CardActionArea sx={{ backgroundColor: COLORS.opacityGray }}>
                    <CardMedia
                      component="img"
                      sx={{
                        height: 317,
                      }}
                      image={
                        item.mainImageFullUrl === ""
                          ? newsAndUpdates1
                          : item.mainImageFullUrl
                      }
                      alt="image"
                    />
                    <CardContent sx={{ height: 100 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        overflow={"hidden"}
                      >
                        {/* {item.title.substring(0,80)}... */}
                        {item.title.length > 80
                          ? `${item.title.substring(0, 80)}...`
                          : `${item.title}`}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions sx={{ backgroundColor: COLORS.opacityGray }}>
                    <Typography
                      fontWeight={"bold"}
                      variant="body2"
                      color="text.secondary"
                    >
                      {formattedDate}
                    </Typography>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>

      </Grid>
    </Box>
  );
};

export default NewsLatestSection;

{
  /* <Typography dangerouslySetInnerHTML={{ __html: item.contentData }} /> */
}


// <Grid
// xs={12}
// md={7}
// item
// container
// display={{ xs: "block", md: "none" }}
// >
// {data?.slice(3, 5)?.map((item) => {
//   const formattedDate = DateFormatedByLanguage(
//     item.newsDate,
//     languageId
//   );
//   return (
//     <Grid
//       key={item.uniqueName}
//       item
//       xs={12}
//       md={6}
//       lg={4}
//       display={"flex"}
//       justifyContent={"center"}
//       p={1}
//     >
//       <Card
//         variant="outlined"
//         sx={{
//           border: "none",
//           maxWidth: 240,
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "space-between",
//           transition: "0.5s",
//           "&:hover": {
//             transform: "Scale(1.1)",
//             zIndex: 2,
//           },
//         }}
//         onClick={() =>
//           navigateToDetails(item.uniqueName, props.insie)
//         }
//       >
//         <CardActionArea sx={{ backgroundColor: COLORS.opacityGray }}>
//           <CardMedia
//             component="img"
//             sx={{
//               height: 317,
//             }}
//             image={
//               item.mainImageFullUrl === ""
//                 ? newsAndUpdates1
//                 : item.mainImageFullUrl
//             }
//             alt="image"
//           />
//           <CardContent sx={{ height: 100 }}>
//             <Typography
//               variant="body2"
//               color="text.secondary"
//               overflow={"hidden"}
//             >
//               {item.title.length > 80
//                 ? `${item.title.substring(0, 80)}...`
//                 : `${item.title}`}
//             </Typography>
//           </CardContent>
//         </CardActionArea>
//         <CardActions sx={{ backgroundColor: COLORS.opacityGray }}>
//           <Typography
//             fontWeight={"bold"}
//             variant="body2"
//             color="text.secondary"
//           >
//             {formattedDate}
//           </Typography>
//         </CardActions>
//       </Card>
//     </Grid>
//   );
// })}
// </Grid>

// <Grid
// xs={12}
// md={5}
// item
// container
// height={470}
// p={1}
// display={{ xs: "none", md: "block" }}
// >
// {data[3] && (
//   <Grid
//     height={"65%"}
//     item
//     xs={12}
//     display={"flex"}
//     justifyContent={"center"}
//     p={1}
//     overflow={"hidden"}
//     sx={{
//       transition: "0.5s",
//       "&:hover": {
//         transform: "Scale(1.1)",
//         zIndex: 2,
//       },
//     }}
//   >
//     <Card
//       onClick={() =>
//         navigateToDetails(data[3].uniqueName, props.insie)
//       }
//       sx={{ height: "100%" }}
//     >
//       <CardActionArea sx={{ height: "100%" }}>
//         <Box sx={{ position: "relative", height: "100%" }}>
//           <img
//             src={data[3].mainImageFullUrl}
//             width={"100%"}
//             height={"100%"}
//           />
//           <Box position={"absolute"} top={"75%"} p={1}>
//             <Typography
//               sx={{ color: COLORS.white }}
//               variant="body2"
//               zIndex={1}
//             >
//               {/* {data[3].title} */}
//               {data[3].title.length > 125
//                 ? `${data[3].title.substring(0, 125)} ...`
//                 : data[3].title}
//             </Typography>
//           </Box>
//         </Box>
//       </CardActionArea>
//     </Card>
//   </Grid>
// )}
// {data[4] && (
//   <Grid
//     height={"35%"}
//     item
//     xs={12}
//     display={"flex"}
//     p={1}
//     sx={{
//       transition: "0.5s",
//       "&:hover": {
//         transform: "Scale(1.1)",
//         zIndex: 2,
//       },
//     }}
//   >
//     <Card variant="outlined" sx={{ display: "flex", width: "100%" }}>
//       <ButtonBase
//         onClick={() =>
//           navigateToDetails(data[4].uniqueName, props.insie)
//         }
//       >
//         <CardMedia
//           component="img"
//           sx={{ width: 220 }}
//           image={data[4].mainImageFullUrl}
//           alt={data[4].title}
//         />
//       </ButtonBase>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "space-between",
//         }}
//         p={1}
//       >
//         <Typography
//           fontWeight={"bold"}
//           fontSize={"1rem"}
//           color="text.secondary"
//           component="div"
//           variant="h5"
//         >
//           {data[4].title}
//         </Typography>
//         <Typography
//           fontWeight={"bold"}
//           variant="body2"
//           color="text.secondary"
//         >
//           {lastItemFormattedDate}
//         </Typography>
//       </Box>
//     </Card>
//   </Grid>
// )}
// </Grid>
