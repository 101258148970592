import { GetValue, SetCookie } from "../utils";

const useGuidedTourCookie = () => {
    const setGuidedTourCookie = (): void => {
      SetCookie(24,"guided-tour","true");
    };
  
    const getGuidedTourCookieValue = (): string | null => {
     return GetValue("guided-tour");
    };
    return { getGuidedTourCookieValue, setGuidedTourCookie };
  };
  
  export default useGuidedTourCookie;
  