import { useContext } from "react";
import { createTheme, PaletteOptions } from "@mui/material";
import { ThemeContext } from "../../../context/ThemeContext";
import { bluePalette, goldPalette } from "../../../theme/theme";
import i18next from 'i18next'

type ThemeOptions = {
  fontSize?: number;
  palette?: PaletteOptions;
  direction?: "ltr" | 'rtl'
};

const useThemeChanger = () => {
  const { changeTheme, themeData,contrastSetGet,isContrast } = useContext(ThemeContext);

  const handleThemeChange = (options: ThemeOptions) => {
    const newTheme = createTheme({
      palette: options?.palette || themeData.palette,
      typography: {
        fontSize: options?.fontSize || themeData.typography.fontSize,
        fontFamily: options.direction === 'rtl' ? 'GEDinarTwo-MediumItalic' : 'Roboto',
      },
      direction: options?.direction || themeData.direction,
    });
    // localStorage.setItem('customeTheme',JSON.stringify(newTheme));
    localStorage.setItem('customeTheme',JSON.stringify({
      palette: options?.palette || themeData.palette,
      typography: {
        fontSize: options?.fontSize || themeData.typography.fontSize,
        fontFamily: options.direction === 'rtl' ? 'GEDinarTwo-MediumItalic' : 'Roboto',
      },
      direction: options?.direction || themeData.direction,
    }));
    changeTheme(newTheme);
  };

  const resetTheme = () => {
    handleThemeChange({
      fontSize: 14,
      palette: goldPalette,
      direction: themeData.direction
    });
    contrastSetGet(false)
  };
  const changeLanguage = () => {
    
    handleThemeChange({
      direction: themeData.direction === "ltr" ? "rtl" : "ltr",
    });
    i18next.changeLanguage(themeData.direction === "ltr" ? "ar" : "en")
  };
  const setArabic = () => {
    
    handleThemeChange({
      direction: "rtl",
    });
    i18next.changeLanguage("ar")
  };
  const contrastSetter = () => {
    handleThemeChange({
      fontSize: 14,
      palette: bluePalette,
      direction: themeData.direction
    });
    contrastSetGet(!isContrast)
  };
  
  return { handleThemeChange, resetTheme,changeLanguage,setArabic, contrastSetter,isContrast };
};

export default useThemeChanger;