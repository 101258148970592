import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../shared/constants";
import useMessages from "../hooks/useMessages";
import { useLanguageID } from "../../../shared/hooks/useLanguageID";
import CustomSkeleton from "../../../shared/components/skelton/CustomSkeleton";
import { useNavigate } from "react-router-dom";

const newsAndUpdates1 = require("../../../assets/images/desktop.png");

interface MessagesSectionProp {}
const MessagesSection: React.FC<MessagesSectionProp> = (props) => {
  const nagivate = useNavigate();
  const { t } = useTranslation();
  const languageId = useLanguageID();
  const { data, isLoading, isError, error } = useMessages(languageId);

  if (isLoading) {
    return <CustomSkeleton />;
  }

  if (isError) {
    return null;
  }
  return (
    <Box id="our-leaders" className="ignore-print">
      <Typography
        variant="h4"
        sx={{
          display: "block",
          marginTop: 2,
          marginBottom: 3,
        }}
      >
        {t("ourleaders")}
      </Typography>
      <Grid
        container
        sx={{ marginTop: 5,direction:'ltr' }}
        justifyContent={{ xs: "center", sm: "flex-start" }}
      >
        <Grid
          xs={12}
          md={12}
          item
          container
          display={"flex"}
          justifyContent={"center"}
        >
          {data?.map((item) => {
            return (
              <Grid
                key={item.uniqueName}
                item
                xs={12}
                md={6}
                lg={2.4}
                xl={3}
                display={"flex"}
                justifyContent={"center"}
                p={1}
              >
                <Card
                  sx={{
                    border: "none",
                    maxWidth: 270,
                    maxHeight: 550,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  {/* <CardActionArea
                    sx={{
                      cursor: "default",
                    }}
                    
                  > */}
                    <CardMedia
                      component="img"
                      sx={{
                        maxWidth: 270,
                        maxHeight: 550,
                      }}
                      image={
                        item.relatedImages &&
                        item.relatedImages.length > 0 &&
                        item.relatedImages[0].fullUrl !== ""
                          ? item.relatedImages[0].fullUrl
                          : newsAndUpdates1
                      }
                      alt="image"
                    />
                    <CardContent sx={{height:'100%',cursor:'default'}}>
                      <Typography
                        variant="body2"
                        textAlign={'center'}
                        color="text.secondary"
                        overflow={"hidden"}
                        fontWeight={"bold"}
                      >
                        {item.title.length > 80
                          ? `${item.title.substring(0, 80)}...`
                          : `${item.title}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        textAlign={'center'}
                        color="text.secondary"
                        overflow={"hidden"}
                        mt={1}
                      >
                        {item.description.length > 80
                          ? `${item.description.substring(0, 80)}...`
                          : `${item.description}`}
                      </Typography>
                    </CardContent>
                  {/* </CardActionArea> */}
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MessagesSection;
