import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import useLoginMutation from '../../shared/hooks/useLoginMutation';
interface LoaderProp {};
const Loader: React.FC<LoaderProp> = (props) => {
  console.count('LoaderProp')
  const { login,token} = useLoginMutation();
  React.useEffect(() => {
    login();
  }, []);
  React.useEffect(() => {
    if(token){
      window.location.href = "/"
    }
  }, [token]);
  return (
    <Box sx={{ height:'100vh',display: 'flex',justifyContent:'center',alignItems:'center' }}>
      <CircularProgress size={'12vh'} />
    </Box>
  )
}

export default Loader